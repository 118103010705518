import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  CircularProgress,
  TextField,
  Switch,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  IconButton,
  Link as MuiLink,
} from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import axios from 'axios';
import Footer from './Footer';
import SubscribeButton from './SubscribeButton';
import { Link } from 'react-router-dom';
import PulseHeader from './PulseHeader';
import PulseTabs from './PulseTabs';

const Pulse = ({ onLogout, userEmail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pulseTopics, setPulseTopics] = useState([]);
  const [trials, setTrials] = useState([]);
  const [newTopic, setNewTopic] = useState('');
  const [error, setError] = useState('');
  const [curatedPulseTopics, setCuratedPulseTopics] = useState([]);
  const [newCuratedTopic, setNewCuratedTopic] = useState('');
  const [availableCuratedTopics, setAvailableCuratedTopics] = useState({});
  const [upgradeMessage, setUpgradeMessage] = useState(null);
  const [upgradeMessageId, setUpgradeMessageId] = useState(null);
  const [isFading, setIsFading] = useState(false);
  const [archivingId, setArchivingId] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState('free');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Move isAdmin declaration before useEffect
  const isAdmin = ['test@test.com', 'mail@andrewjm.co'].includes(userEmail);

  // Fetch pulse topics and trials on component mount
  useEffect(() => {
    fetchPulseTopics();
    fetchTrials();
    fetchAvailableCuratedTopics();
    fetchSubscriptionPlan();
  }, []);

  useEffect(() => {
    if (isAdmin) {
      fetchCuratedPulseTopics();
    }
  }, [isAdmin]);

  const fetchPulseTopics = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics`,
        { withCredentials: true }
      );
      setPulseTopics(response.data);
    } catch (error) {
      console.error('Error fetching pulse topics:', error);
      setError('Failed to fetch pulse topics');
    }
  };

  const fetchTrials = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/trials`,
        { withCredentials: true }
      );
      setTrials(response.data);
    } catch (error) {
      console.error('Error fetching trials:', error);
      setError('Failed to fetch trials');
    }
  };

  const fetchCuratedPulseTopics = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics`,
        { withCredentials: true }
      );
      setCuratedPulseTopics(response.data);
    } catch (error) {
      console.error('Error fetching curated pulse topics:', error);
      setError('Failed to fetch curated pulse topics');
    }
  };

  const fetchAvailableCuratedTopics = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics/available`,
        { withCredentials: true }
      );
      setAvailableCuratedTopics(response.data);
    } catch (error) {
      console.error('Error fetching available curated topics:', error);
      setError('Failed to fetch available curated topics');
    }
  };

  const fetchSubscriptionPlan = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/payments/subscription-status`,
        { withCredentials: true }
      );
      setSubscriptionPlan(response.data.subscription_plan);
    } catch (error) {
      console.error('Error fetching subscription plan:', error);
    }
  };

  const handleCreateTopic = async (e) => {
    e.preventDefault();
    if (!newTopic.trim()) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics`,
        { topic: newTopic },
        { withCredentials: true }
      );
      setPulseTopics([response.data, ...pulseTopics]);
      setNewTopic('');
    } catch (error) {
      console.error('Error creating pulse topic:', error);
      setError('Failed to create pulse topic');
    }
  };

  const handleToggleTopic = async (id, currentEnabled) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics/${id}/toggle`,
        {},
        { withCredentials: true }
      );
      setPulseTopics(pulseTopics.map(topic =>
        topic.id === id ? response.data : topic
      ));
    } catch (error) {
      console.error('Error toggling pulse topic:', error);
      if (error.response?.status === 403) {
        setIsFading(false);
        setUpgradeMessageId(id);
        setUpgradeMessage(error.response.data.message);
        
        // Start fade after 4 seconds
        setTimeout(() => {
          setIsFading(true);
        }, 4000);

        // Remove message after fade completes
        setTimeout(() => {
          setUpgradeMessageId(null);
          setUpgradeMessage(null);
          setIsFading(false);
        }, 5000);
      }
    }
  };

  const handleToggleTrial = async (id, currentEnabled) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/trials/${id}/toggle`,
        {},
        { withCredentials: true }
      );
      setTrials(trials.map(trial => 
        trial.id === id ? response.data : trial
      ));
    } catch (error) {
      console.error('Error toggling trial:', error);
      setError('Failed to toggle trial');
    }
  };

  const handleCreateCuratedTopic = async (e) => {
    e.preventDefault();
    if (!newCuratedTopic.trim()) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics`,
        { topic: newCuratedTopic },
        { withCredentials: true }
      );
      setCuratedPulseTopics([response.data, ...curatedPulseTopics]);
      setNewCuratedTopic('');
    } catch (error) {
      console.error('Error creating curated pulse topic:', error);
      setError('Failed to create curated pulse topic');
    }
  };

  const handleToggleCuratedTopic = async (id, currentEnabled) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics/${id}/toggle`,
        {},
        { withCredentials: true }
      );
      setCuratedPulseTopics(curatedPulseTopics.map(topic => 
        topic.id === id ? response.data : topic
      ));
    } catch (error) {
      console.error('Error toggling curated pulse topic:', error);
      setError('Failed to toggle curated pulse topic');
    }
  };

  const handleToggleSubscription = async (id, currentSubscribed) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics/${id}/subscribe`,
        {},
        { withCredentials: true }
      );

      setAvailableCuratedTopics(prevTopics => {
        const newTopics = { ...prevTopics };
        
        Object.keys(newTopics).forEach(category => {
          Object.keys(newTopics[category]).forEach(subCategory => {
            newTopics[category][subCategory] = newTopics[category][subCategory].map(topic =>
              topic.id === id ? { ...topic, isSubscribed: response.data.isSubscribed } : topic
            );
          });
        });
        
        return newTopics;
      });
    } catch (error) {
      console.error('Error toggling subscription:', error);
      if (error.response?.status === 403) {
        setIsFading(false);
        setUpgradeMessageId(id);
        setUpgradeMessage(error.response.data.message);
        
        // Start fade after 4 seconds
        setTimeout(() => {
          setIsFading(true);
        }, 4000);

        // Remove message after fade completes
        setTimeout(() => {
          setUpgradeMessageId(null);
          setUpgradeMessage(null);
          setIsFading(false);
        }, 5000);
      }
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/logout`,
        {},
        { withCredentials: true }
      );
      onLogout();
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateCategories = async (id, category, sub_category) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics/${id}/categories`,
        { category, sub_category },
        { withCredentials: true }
      );
      setCuratedPulseTopics(curatedPulseTopics.map(topic => 
        topic.id === id ? response.data : topic
      ));
    } catch (error) {
      console.error('Error updating categories:', error);
      setError('Failed to update categories');
    }
  };

  const handleArchive = async (id) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics/${id}/archive`,
        {},
        { withCredentials: true }
      );
      setPulseTopics(pulseTopics.filter(topic => topic.id !== id));
      setArchivingId(null);
    } catch (error) {
      console.error('Error archiving pulse topic:', error);
    }
  };

  const renderTopic = (topic) => (
    <React.Fragment key={topic.id}>
      <ListItem
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px solid #eaeaea',
          bgcolor: '#f8f8f8',
          borderRadius: 1,
          mb: 1,
          py: 1,
          px: 2,
          gap: 2,
        }}
        secondaryAction={null}
      >
        <ListItemText 
          primary={topic.topic} 
          sx={{ 
            flex: '1 1 auto',
            mr: 2,
            '& .MuiTypography-root': {
              wordBreak: 'break-word',
            }
          }}
        />
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            flex: '0 0 auto',
            ml: 'auto',
          }}
        >
          {!topic.enabled && (
            <>
              {archivingId === topic.id ? (
                <Box sx={{ display: 'flex', gap: 1, mr: 2 }}>
                  <MuiLink
                    component="button"
                    underline="hover"
                    onClick={() => handleArchive(topic.id)}
                    sx={{ color: '#dc3545', cursor: 'pointer', whiteSpace: 'nowrap' }}
                  >
                    archive
                  </MuiLink>
                  <MuiLink
                    component="button"
                    underline="hover"
                    onClick={() => setArchivingId(null)}
                    sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  >
                    cancel
                  </MuiLink>
                </Box>
              ) : (
                <IconButton 
                  onClick={() => setArchivingId(topic.id)}
                  size="small"
                  sx={{ mr: 0, pr: 0 }}
                >
                  <ArchiveIcon fontSize="small" />
                </IconButton>
              )}
            </>
          )}
          <Switch
            edge="end"
            checked={topic.enabled}
            onChange={() => handleToggleTopic(topic.id, topic.enabled)}
          />
        </Box>
      </ListItem>
      {upgradeMessageId === topic.id && (
        <Box 
          sx={{ 
            mb: 2,
            p: 1,
            backgroundColor: '#FFF3CD',
            border: '1px solid #FFE69C',
            borderRadius: 1,
            color: '#856404',
            opacity: isFading ? 0 : 1,
            transition: 'opacity 1s ease-out',
          }}
        >
          <Typography variant="body2" sx={{ textAlign: 'left' }}>
            {upgradeMessage}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container maxWidth="md" sx={{ mt: 0, mb: 0 }}>
        <PulseHeader 
          userEmail={userEmail}
          isLoading={isLoading}
          handleLogout={onLogout}
        />

        <PulseTabs currentTab={0} />

        {trials && trials.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h3" sx={{ mb: 0, textAlign: 'left' }}>
              Trial
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
              Toggle off to disable.
            </Typography>
            <List>
              {trials.map((trial) => (
                <ListItem
                  key={trial.id}
                  sx={{
                    border: '1px solid #eaeaea',
                    borderRadius: 1,
                    mb: 1,
                    bgcolor: '#f8f8f8',
                  }}
                  secondaryAction={
                    <Switch
                      edge="end"
                      checked={trial.enabled}
                      onChange={() => handleToggleTrial(trial.id, trial.enabled)}
                    />
                  }
                >
                  <ListItemText primary={trial.topic} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Box sx={{mb: 4 }}>
          <Typography variant="h3" sx={{ mb: 0, textAlign: 'left' }}>
            Custom pulses
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
            {(() => {
              switch(subscriptionPlan) {
                case 'premium':
                  return "Enable unlimited custom pulses. Keep it specific enough to be interesting, but broad enough to have new content every day.";
                case 'plus':
                  return "Enable up to 10 custom pulses. Keep it specific enough to be interesting, but broad enough to have new content every day.";
                default:
                  return "Enable up to 1 custom pulse. Keep it specific enough to be interesting, but broad enough to have new content every day.";
              }
            })()}
          </Typography>
          <Box component="form" onSubmit={handleCreateTopic} sx={{ display: 'flex', gap: 2 }}>
            <TextField
              fullWidth
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              placeholder="Enter a topic to track..."
              size="small"
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: 'black',
                '&:hover': { bgcolor: '#333' }
              }}
            >
              Add
            </Button>
          </Box>
        </Box>

        <List>
          {pulseTopics.map((topic) => (
            <React.Fragment key={topic.id}>
              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: '1px solid #eaeaea',
                  bgcolor: '#f8f8f8',
                  borderRadius: 1,
                  mb: 1,
                  py: 1,
                  px: 2,
                  gap: 2,
                }}
                secondaryAction={null}
              >
                <ListItemText 
                  primary={topic.topic} 
                  sx={{ 
                    flex: '1 1 auto',
                    mr: 2,
                    '& .MuiTypography-root': {
                      wordBreak: 'break-word',
                    }
                  }}
                />
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1,
                    flex: '0 0 auto',
                    ml: 'auto',
                  }}
                >
                  {!topic.enabled && (
                    <>
                      {archivingId === topic.id ? (
                        <Box sx={{ display: 'flex', gap: 1, mr: 2 }}>
                          <MuiLink
                            component="button"
                            underline="hover"
                            onClick={() => handleArchive(topic.id)}
                            sx={{ color: '#dc3545', cursor: 'pointer', whiteSpace: 'nowrap' }}
                          >
                            archive
                          </MuiLink>
                          <MuiLink
                            component="button"
                            underline="hover"
                            onClick={() => setArchivingId(null)}
                            sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                          >
                            cancel
                          </MuiLink>
                        </Box>
                      ) : (
                        <IconButton 
                          onClick={() => setArchivingId(topic.id)}
                          size="small"
                          sx={{ mr: 0, pr: 0 }}
                        >
                          <ArchiveIcon fontSize="small" />
                        </IconButton>
                      )}
                    </>
                  )}
                  <Switch
                    edge="end"
                    checked={topic.enabled}
                    onChange={() => handleToggleTopic(topic.id, topic.enabled)}
                  />
                </Box>
              </ListItem>
              {upgradeMessageId === topic.id && (
                <Box 
                  sx={{ 
                    mb: 2,
                    p: 1,
                    backgroundColor: '#FFF3CD',
                    border: '1px solid #FFE69C',
                    borderRadius: 1,
                    color: '#856404',
                    opacity: isFading ? 0 : 1,
                    transition: 'opacity 1s ease-out',
                  }}
                >
                  <Typography variant="body2" sx={{ textAlign: 'left' }}>
                    {upgradeMessage}
                  </Typography>
                </Box>
              )}
            </React.Fragment>
          ))}
        </List>

        <Typography variant="h3" sx={{ mb: 0, textAlign: 'left' }}>
          Popular pulses
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
          {subscriptionPlan === 'free' 
            ? "Enable up to 2 of our most popular pulses."
            : "Enable unlimited popular pulses."}
        </Typography>
        {Object.entries(availableCuratedTopics).map(([category, subCategories]) => (
          <Box key={category} sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 0, textAlign: 'left' }}>
              {category}
            </Typography>
            {Object.entries(subCategories).map(([subCategory, topics]) => (
              <Box key={subCategory} sx={{ mb: 0 }}>
                <Typography variant="body1" sx={{ mb: 0, fontStyle: 'italic', textAlign: 'left' }}>
                  {subCategory}
                </Typography>
                <List>
                  {topics.map(topic => (
                    <React.Fragment key={topic.id}>
                      <ListItem
                        sx={{
                          border: '1px solid #eaeaea',
                          bgcolor: '#f8f8f8',
                          borderRadius: 1,
                          mb: 1,
                        }}
                        secondaryAction={
                          <Switch
                            edge="end"
                            checked={topic.isSubscribed}
                            onChange={() => handleToggleSubscription(topic.id, topic.isSubscribed)}
                          />
                        }
                      >
                        <ListItemText primary={topic.topic} />
                      </ListItem>
                      {upgradeMessageId === topic.id && (
                        <Box 
                          sx={{ 
                            mb: 2,
                            p: 1,
                            backgroundColor: '#FFF3CD',
                            border: '1px solid #FFE69C',
                            borderRadius: 1,
                            color: '#856404',
                            opacity: isFading ? 0 : 1,
                            transition: 'opacity 1s ease-out',
                          }}
                        >
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                            {upgradeMessage}
                          </Typography>
                        </Box>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
        ))}

        {isAdmin && (
          <Box sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h3" sx={{ mb: 2, textAlign: 'left' }}>
                Curated Pulse Topics
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
              Admin only.
            </Typography>
            <Box component="form" onSubmit={handleCreateCuratedTopic} sx={{ display: 'flex', gap: 2 }}>
              <TextField
                fullWidth
                value={newCuratedTopic}
                onChange={(e) => setNewCuratedTopic(e.target.value)}
                placeholder="Enter a curated topic..."
                size="small"
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: 'black',
                  '&:hover': { bgcolor: '#333' }
                }}
              >
                Add
              </Button>
            </Box>

            <List sx={{ mt: 2 }}>
              {curatedPulseTopics.map((topic) => (
                <ListItem
                  key={topic.id}
                  sx={{
                    border: '1px solid #eaeaea',
                    borderRadius: 1,
                    mb: 1,
                    bgcolor: '#f8f8f8',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    py: 2
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <ListItemText primary={topic.topic} />
                    <Switch
                      edge="end"
                      checked={topic.enabled}
                      onChange={() => handleToggleCuratedTopic(topic.id, topic.enabled)}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                      size="small"
                      label="Category"
                      value={topic.category || ''}
                      onChange={(e) => handleUpdateCategories(topic.id, e.target.value, topic.sub_category)}
                      sx={{ flex: 1 }}
                    />
                    <TextField
                      size="small"
                      label="Sub-category"
                      value={topic.sub_category || ''}
                      onChange={(e) => handleUpdateCategories(topic.id, topic.category, e.target.value)}
                      sx={{ flex: 1 }}
                    />
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Container>
      <Footer />
    </Box>
  );
};

export default Pulse; 