import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './SubscribeButton.css';

const SubscribeButton = () => {
  const navigate = useNavigate();
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    status: null,
    cancelAtPeriodEnd: false,
    subscription_plan: 'free'
  });

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  const fetchSubscriptionStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/payments/subscription-status`,
        { withCredentials: true }
      );
      setSubscriptionDetails({
        status: response.data.status,
        cancelAtPeriodEnd: response.data.cancelAtPeriodEnd,
        subscription_plan: response.data.subscription_plan
      });
    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  };

  const handleClick = () => {
    navigate('/account');
  };

  const getButtonText = () => {
    switch (subscriptionDetails.subscription_plan) {
      case 'premium':
        return 'Premium';
      case 'plus':
        return 'Plus';
      default:
        return 'Upgrade to Plus';
    }
  };

  return (
    <div className="subscribe-container-header">
      <button 
        onClick={handleClick}
        className="subscribe-button-header"
      >
        {getButtonText()}
      </button>
    </div>
  );
};

export default SubscribeButton; 