import { marked } from 'marked';

export const getMarkedRenderer = (referenceDisplay = 'number') => {
  const renderer = new marked.Renderer();

  switch (referenceDisplay) {
    case 'domain':
      renderer.link = (href, title, text) => {
        try {
          const url = typeof href === 'object' ? href.href : href;
          const domain = new URL(url).hostname.replace(/^www\./, '');
          
          return ` <a href="${url}" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style="color: #666; text-decoration: underline; transition: color 0.2s ease-in-out"
                    onmouseover="this.style.color='#0066cc'" 
                    onmouseout="this.style.color='#666'"
                  >${domain}</a>`;
        } catch (error) {
          console.error('Error in domain renderer:', error);
          return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
        }
      };
      break;

    case 'url':
      renderer.link = (href, title, text) => {
        try {
          const url = typeof href === 'object' ? href.href : href;
          
          return ` <a href="${url}" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style="color: #666; text-decoration: underline; transition: color 0.2s ease-in-out"
                    onmouseover="this.style.color='#0066cc'" 
                    onmouseout="this.style.color='#666'"
                  >${url}</a>`;
        } catch (error) {
          console.error('Error in URL renderer:', error);
          return ` <a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
        }
      };
      break;

    default: // 'number'
      // Use default marked behavior for reference numbers
      break;
  }

  return renderer;
};

export const parseWithRenderer = (content, referenceDisplay = 'number') => {
  return marked.parse(content, {
    renderer: getMarkedRenderer(referenceDisplay),
    breaks: true
  });
}; 