import React from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import SubscribeButton from './SubscribeButton';

const PulseHeader = ({ userEmail, isLoading, handleLogout }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLocalLogout = async () => {
    try {
      // Call backend logout first
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/logout`,
        {
          method: 'POST',
          credentials: 'include',
        }
      );

      // Whether backend succeeds or fails, clean up frontend
      localStorage.clear(); // Clear all local storage
      
      // Finally call the parent's logout handler
      handleLogout();
    } catch (error) {
      console.error('Logout error:', error);
      // Still logout even if request fails
      localStorage.clear();
      handleLogout();
    }
  };

  return (
    <Box 
      sx={{ 
        display: 'flex',
        alignItems: 'center',
        mt: 1,
        mb: 1,
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <SubscribeButton />
        <Typography 
          component="h1" 
          variant={isMobile ? "h5" : "h4"} 
          sx={{ 
            fontWeight: 600,
            minWidth: 'fit-content',
          }}
        >
          dist*ll
        </Typography>
      </Box>

      <Button
        onClick={handleLocalLogout}
        variant="text"
        disabled={isLoading}
        sx={{
          py: 1,
          px: 2,
          color: 'black',
          border: '1px solid transparent',
          '&:hover': {
            border: '1px solid black',
            bgcolor: 'transparent',
          },
          minWidth: 'auto',
        }}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Logout'}
      </Button>
    </Box>
  );
};

export default PulseHeader; 