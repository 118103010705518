import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PulseTabs = ({ currentTab }) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState(currentTab);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    navigate(newValue === 0 ? '/pulse' : newValue === 1 ? '/feed' : '/settings');
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange}
        aria-label="pulse navigation tabs"
      >
        <Tab label="Subscriptions" />
        <Tab label="Feed" />
        <Tab label="Settings" />
      </Tabs>
    </Box>
  );
};

export default PulseTabs; 