import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Card, 
  CardContent, 
  Switch,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PulseHeader from './PulseHeader';
import PulseTabs from './PulseTabs';
import Footer from './Footer';
import axios from 'axios';

const Settings = ({ onLogout, userEmail }) => {
  const [emailDigestEnabled, setEmailDigestEnabled] = useState(false);
  const [isLoadingPreferences, setIsLoadingPreferences] = useState(true);
  const [referenceDisplay, setReferenceDisplay] = useState('number');
  const [isLoadingReferenceDisplay, setIsLoadingReferenceDisplay] = useState(true);

  useEffect(() => {
    const fetchEmailPreferences = async () => {
      try {
        setIsLoadingPreferences(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/email-preferences`,
          { withCredentials: true }
        );
        setEmailDigestEnabled(response.data?.emailDigestEnabled ?? false);
      } catch (error) {
        console.error('Error fetching email preferences:', error);
        setEmailDigestEnabled(false);
      } finally {
        setIsLoadingPreferences(false);
      }
    };

    fetchEmailPreferences();
  }, []);

  useEffect(() => {
    const fetchReferenceDisplay = async () => {
      try {
        setIsLoadingReferenceDisplay(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/user`,
          { credentials: 'include' }
        );
        if (!response.ok) throw new Error('Failed to fetch user data');
        const data = await response.json();
        setReferenceDisplay(data.referenceDisplaySetting || 'number');
      } catch (error) {
        console.error('Error fetching reference display:', error);
      } finally {
        setIsLoadingReferenceDisplay(false);
      }
    };

    fetchReferenceDisplay();
  }, []);

  const handleEmailDigestToggle = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/email-preferences`,
        { emailDigestEnabled: !emailDigestEnabled },
        { withCredentials: true }
      );
      
      const newValue = response.data?.emailDigestEnabled ?? emailDigestEnabled;
      setEmailDigestEnabled(newValue);
    } catch (error) {
      console.error('Error updating email preferences:', error);
    }
  };

  const handleReferenceDisplayChange = async (event) => {
    const newValue = event.target.value;
    setReferenceDisplay(newValue);
    
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/api/reference-display`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ setting: newValue })
        }
      );
    } catch (error) {
      console.error('Error updating reference display:', error);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 0, mb: 4 }}>
      <PulseHeader 
        userEmail={userEmail}
        isLoading={false}
        handleLogout={onLogout}
      />

      <PulseTabs currentTab={2} />

      <Box sx={{ textAlign: 'left', mb: 4 }}>

        <Card 
          sx={{ 
            mb: 3,
            mt: 0,
            backgroundColor: '#f8f8f8',
            border: '1px solid #eaeaea',
            boxShadow: 'none',
          }}
        >
          <CardContent sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            py: 1,
            '&:last-child': { pb: 1 },
          }}>
            <EmailIcon sx={{ color: '#666' }} />
            <Typography variant="body1" color="text.secondary" textAlign="left">
              Sending daily pulses to <strong>{userEmail}</strong> between 7am and 8am EST
            </Typography>
          </CardContent>
        </Card>

        <Card 
          sx={{ 
            mb: 3,
            mt: 2,
            backgroundColor: '#f8f8f8',
            border: '1px solid #eaeaea',
            boxShadow: 'none',
          }}
        >
          <CardContent>
            <Typography 
              variant="body1" 
              color="text.secondary" 
              sx={{ 
                mb: 2,
                fontWeight: 'bold',
                textAlign: 'left'
              }}
            >
              Email Digest
            </Typography>

            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <Typography variant="body1" color="text.secondary">
                Bundle daily pulses into a digest
              </Typography>
              {!isLoadingPreferences ? (
                <Switch
                  checked={emailDigestEnabled}
                  onChange={handleEmailDigestToggle}
                  inputProps={{ 'aria-label': 'toggle email digest' }}
                />
              ) : (
                <CircularProgress size={24} />
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Card 
          sx={{ 
            mb: 3,
            mt: 2,
            backgroundColor: '#f8f8f8',
            border: '1px solid #eaeaea',
            boxShadow: 'none',
          }}
        >
          <CardContent>
            <Typography 
              variant="body1" 
              color="text.secondary" 
              sx={{ 
                mb: 2,
                fontWeight: 'bold',
                textAlign: 'left'
              }}
            >
              Reference Link Display
            </Typography>
            
            {isLoadingReferenceDisplay ? (
              <CircularProgress size={24} />
            ) : (
              <RadioGroup
                value={referenceDisplay}
                onChange={handleReferenceDisplayChange}
              >
                <FormControlLabel 
                  value="number" 
                  control={<Radio />} 
                  label={
                    <Typography variant="body1" color="text.secondary">
                      Display reference number (e.g., [1])
                    </Typography>
                  }
                />
                <FormControlLabel 
                  value="domain" 
                  control={<Radio />} 
                  label={
                    <Typography variant="body1" color="text.secondary">
                      Display domain (e.g., example.com)
                    </Typography>
                  }
                />
                <FormControlLabel 
                  value="url" 
                  control={<Radio />} 
                  label={
                    <Typography variant="body1" color="text.secondary">
                      Display full URL (e.g., www.example.com/page/4)
                    </Typography>
                  }
                />
              </RadioGroup>
            )}
          </CardContent>
        </Card>
      </Box>

      <Footer />
    </Container>
  );
};

export default Settings; 