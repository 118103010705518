import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import SignupForm from './components/SignupForm';
import LoginForm from './components/LoginForm';
import LoggedIn from './components/LoggedIn';
import Perplexity from './components/Perplexity';
import PasswordResetRequestForm from './components/PasswordResetRequestForm';
import PasswordResetForm from './components/PasswordResetForm';
import theme from './theme';
import './App.css';
import Pulse from './components/Pulse';
import Account from './components/Account';
import Feed from './components/Feed';
import Settings from './components/Settings';

// Configure axios defaults
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Create a new component for routes
function AppRoutes({ isLoggedIn, setCurrentView, handleLoginSuccess, resetToken, handleLogout, userEmail }) {
  const navigate = useNavigate();
  const location = useLocation();

  // If user is not logged in, save the attempted URL
  useEffect(() => {
    if (!isLoggedIn && location.pathname !== '/login' && location.pathname !== '/signup') {
      localStorage.setItem('redirectUrl', location.pathname);
    }
  }, [isLoggedIn, location]);

  return (
    <Routes>
      <Route path="/login" element={
        isLoggedIn ? <Navigate to={localStorage.getItem('redirectUrl') || '/pulse'} /> : 
        <LoginForm 
          onToggleForm={() => setCurrentView('signup')}
          onLoginSuccess={handleLoginSuccess}
          onForgotPassword={() => setCurrentView('resetRequest')}
        />
      } />
      
      <Route path="/signup" element={
        isLoggedIn ? <Navigate to={localStorage.getItem('redirectUrl') || '/pulse'} /> : 
        <SignupForm 
          onLoginSuccess={handleLoginSuccess}
          onToggleForm={() => navigate('/login')}
        />
      } />

      <Route path="/reset-request" element={
        isLoggedIn ? <Navigate to="/pulse" /> : 
        <PasswordResetRequestForm 
          onBackToLogin={() => navigate('/login')}
        />
      } />

      <Route path="/reset-password/:token" element={
        isLoggedIn ? <Navigate to="/pulse" /> : 
        <PasswordResetForm
          onBackToLogin={() => navigate('/login')}
        />
      } />

      <Route path="/pulse" element={
        isLoggedIn ? (
          <Pulse onLogout={handleLogout} userEmail={userEmail} />
        ) : (
          <Navigate to="/" replace />
        )
      } />

      <Route path="/account" element={
        isLoggedIn ? (
          <Account userEmail={userEmail} />
        ) : (
          <Navigate to="/login" state={{ from: '/account' }} />
        )
      } />

      <Route path="/feed" element={
        isLoggedIn ? (
          <Feed 
            onLogout={handleLogout}
            userEmail={userEmail}
          />
        ) : (
          <Navigate to="/" replace />
        )
      } />

      <Route path="/settings" element={
        isLoggedIn ? (
          <Settings 
            onLogout={handleLogout}
            userEmail={userEmail}
          />
        ) : (
          <Navigate to="/" replace />
        )
      } />

      <Route path="/" element={<Perplexity />} />
      <Route path="/*" element={<Perplexity />} />
    </Routes>
  );
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [currentView, setCurrentView] = useState('login');
  const [resetToken, setResetToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/verify-token`, 
        { 
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      
      if (response.data.isValid && response.data.user && response.data.user.email) {
        setIsLoggedIn(true);
        setUserEmail(response.data.user.email);
      } else {
        // Just clean up state without reload
        localStorage.clear();
        delete axios.defaults.headers.common['Authorization'];
        setIsLoggedIn(false);
        setUserEmail(null);
      }
    } catch (error) {
      console.error('Auth check failed:', error);
      // Just clean up state without reload
      localStorage.clear();
      delete axios.defaults.headers.common['Authorization'];
      setIsLoggedIn(false);
      setUserEmail(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginSuccess = (email) => {
    setIsLoggedIn(true);
    setUserEmail(email);
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      localStorage.removeItem('redirectUrl');
    }
  };

  const handleLogout = async () => {
    try {
      // First, call the backend logout endpoint
      await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      // Clear state
      localStorage.clear();
      delete axios.defaults.headers.common['Authorization'];
      setIsLoggedIn(false);
      setUserEmail(null);
      
      // Only do the page reload for explicit logout actions
      window.location.replace('/');
    }
  };

  if (isLoading && localStorage.getItem('token')) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <AppRoutes 
            isLoggedIn={isLoggedIn}
            setCurrentView={setCurrentView}
            handleLoginSuccess={handleLoginSuccess}
            resetToken={resetToken}
            handleLogout={handleLogout}
            userEmail={userEmail}
          />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
