import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Link,
} from '@mui/material';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

const Perplexity = () => {
  const [input, setInput] = useState('');
  const [email, setEmail] = useState('');
  const [pulses, setPulses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch pulses on component mount
  useEffect(() => {
    fetchPulses();
  }, []);

  const fetchPulses = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/pulses`,
        { withCredentials: true }
      );
      setPulses(response.data);
    } catch (error) {
      console.error('Error fetching pulses:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/perplexity`,
        { input, email },
        { withCredentials: true }
      );

      // Clear form and refresh pulses
      setInput('');
      setEmail('');
      fetchPulses();
    } catch (error) {
      console.error('Error:', error);
      setError(error.response?.data?.error || 'An error occurred. Please use HTTPS while we resolve this issue.');
    } finally {
      setIsLoading(false);
    }
  };

  const markdownStyles = {
    // Headers
    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '1rem',
      textAlign: 'left', // Added to float text left
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
      marginBottom: '0.75rem',
      textAlign: 'left', // Added to float text left
    },
    h3: {
      fontSize: '1.1rem',
      fontWeight: 600,
      marginBottom: '0.5rem',
      textAlign: 'left', // Added to float text left
    },
    // Lists
    ul: {
      marginLeft: '1.5rem',
      marginBottom: '1rem',
      textAlign: 'left', // Added to float text left
    },
    li: {
      marginBottom: '0.25rem',
      textAlign: 'left', // Added to float text left
    },
    // Paragraphs
    p: {
      marginBottom: '1rem',
      lineHeight: 1.6,
      textAlign: 'left', // Added to float text left
    },
    // Links
    a: {
      color: 'blue',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      textAlign: 'left', // Added to float text left
    },
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          mb={1}
          sx={{
            mb: 0,
            marginBottom: '15px',
            fontWeight: 200,
            textAlign: 'left',
            textTransform: 'none',
          }}
        >
          <strong>dist*ll</strong> • <i>be the most informed person in the room</i>
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 4,
            textAlign: 'left',
          }}
        >
          Keep a daily pulse on <b>literally any topic</b> you can think of.
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mb: 4,
          }}
        >
          <TextField
            required
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            label="Topic to keep a pulse on"
            placeholder="e.g. 'AI and Politics', 'Emerging new media artists', 'Scientific discoveries', etc."
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
          <TextField
            required
            fullWidth
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Your email address"
            placeholder="email@example.com"
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              sx={{
                bgcolor: 'black',
                '&:hover': {
                  bgcolor: 'darkgray',
                },
                minWidth: 100,
              }}
            >
              {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Quick 5 day sample'}
            </Button>
            <Typography variant="body1" sx={{ fontStyle: 'italic', alignSelf: 'center', ml: 1 }}>
              Create a free account for more →
            </Typography>
            <a 
              href="/signup" 
              style={{ 
                marginLeft: 'auto', 
                textDecoration: 'underline', 
                alignSelf: 'center', 
                color: 'black', 
                ':hover': { color: 'blue !important' } 
              }}
            >
              Signup / Login
            </a>
          </Box>
        </Box>

        {error && (
          <Typography
            color="error"
            sx={{ mb: 2, textAlign: 'left' }}
          >
            {error}
          </Typography>
        )}

        <hr style={{ border: '1px solid #eaeaea', margin: '50px 0' }} />

        <Typography variant="h5" sx={{ mb: 2, textAlign: 'left', fontWeight: 'bold' }}>
            How it works →
        </Typography>
        <Box>
          <Typography variant="h4" sx={{ fontWeight: 200, mt: 2, backgroundColor: '#d9dbda', color: 'black', p: 4, borderRadius: 2, textAlign: 'left' }}>
            1. Create pulses on literally <strong>any topic</strong> you can think of
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: 200, mt: 2, backgroundColor: '#d9dbda', color: 'black', p: 4, borderRadius: 2, textAlign: 'left' }}>
            2. dist*ll finds <strong>new webpages</strong> related to your pulse every day
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: 200, mt: 2, backgroundColor: '#d9dbda', color: 'black', p: 4, borderRadius: 2, textAlign: 'left' }}>
            3. Everyday, an <strong>LLM reads, summarizes, and emails</strong> you key takeaways
          </Typography>
        </Box>

        <hr style={{ border: '1px solid #eaeaea', margin: '50px 0' }} />

        {/* Display pulses */}
        <Box sx={{ mt: 6 }}>
          <Typography variant="h5" sx={{ mb: 2, textAlign: 'left', fontWeight: 'bold' }}>
            Recent Pulses →
          </Typography>
          {pulses.map((pulse) => (
            <Paper
              key={pulse.id}
              elevation={0}
              sx={{
                p: 3,
                mb: 3,
                border: '1px solid #eaeaea',
              }}
            >
              <Typography variant="caption" color="text.secondary" sx={{ mb: 2, display: 'block' }}>
                {new Date(pulse.createdAt).toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                Topic: {pulse.trial ? pulse.trial.topic : pulse.pulseTopic.topic}
              </Typography>
              <ReactMarkdown
                components={{
                  h1: ({ node, ...props }) => (
                    <Typography variant="h5" style={markdownStyles.h1} {...props} />
                  ),
                  h2: ({ node, ...props }) => (
                    <Typography variant="h6" style={markdownStyles.h2} {...props} />
                  ),
                  h3: ({ node, ...props }) => (
                    <Typography variant="subtitle1" style={markdownStyles.h3} {...props} />
                  ),
                  p: ({ node, ...props }) => (
                    <Typography variant="body1" style={markdownStyles.p} {...props} />
                  ),
                  ul: ({ node, ...props }) => (
                    <Box component="ul" style={markdownStyles.ul} {...props} />
                  ),
                  li: ({ node, ...props }) => (
                    <Box component="li" style={markdownStyles.li} {...props} />
                  ),
                  a: ({ node, href, ...props }) => (
                    <Link
                      href={href}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={markdownStyles.a}
                      {...props}
                    />
                  ),
                }}
              >
                {pulse.content}
              </ReactMarkdown>
            </Paper>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Perplexity; 