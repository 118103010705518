import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  CircularProgress,
  Pagination,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListSubheader
} from '@mui/material';
import { marked } from 'marked';
import PulseHeader from './PulseHeader';
import Footer from './Footer';
import PulseTabs from './PulseTabs';
import { getMarkedRenderer } from '../utils/markedRenderer';

const Feed = ({ onLogout, userEmail }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pulses, setPulses] = useState([]);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTopic, setSelectedTopic] = useState('all');
  const [enabledTopics, setEnabledTopics] = useState({
    custom: [],
    popular: []
  });
  const [referenceDisplay, setReferenceDisplay] = useState('number');

  useEffect(() => {
    fetchEnabledTopics();
  }, []);

  useEffect(() => {
    fetchPulses(page);
  }, [page, selectedTopic, referenceDisplay]);

  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/user`,
          { credentials: 'include' }
        );
        if (!response.ok) throw new Error('Failed to fetch user data');
        const data = await response.json();
        console.log('Fetched reference display setting:', data.referenceDisplaySetting);
        setReferenceDisplay(data.referenceDisplaySetting || 'number');
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };

    fetchUserPreferences();
  }, []);

  const fetchEnabledTopics = async () => {
    try {
      // Fetch custom pulse topics
      const customResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics`,
        { credentials: 'include' }
      );
      const customData = await customResponse.json();
      
      // Fetch curated pulse topics
      const curatedResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/curated-pulse-topics/subscribed`,
        { credentials: 'include' }
      );
      const curatedData = await curatedResponse.json();

      // Filter enabled topics
      const enabledCustomTopics = customData.filter(topic => topic.enabled);
      const enabledCuratedTopics = curatedData;  // Already filtered by enabled in backend

      setEnabledTopics({
        custom: enabledCustomTopics,
        popular: enabledCuratedTopics
      });
    } catch (error) {
      console.error('Error fetching enabled topics:', error);
      setError('Failed to load topics');
    }
  };

  const fetchPulses = async (currentPage) => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_API_URL}/api/pulses/feed?page=${currentPage}`;
      
      // Add topic filter if a specific topic is selected
      if (selectedTopic !== 'all') {
        url += `&topic=${encodeURIComponent(selectedTopic)}`;
      }
      
      const response = await fetch(url, { credentials: 'include' });
      
      if (!response.ok) {
        throw new Error('Failed to fetch pulses');
      }

      const data = await response.json();
      const groupedPulses = groupPulsesByDate(data.pulses);
      setPulses(groupedPulses);
      setTotalPages(data.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching pulses:', error);
      setError('Failed to load pulses');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
    setPage(1); // Reset to first page when changing topics
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const groupPulsesByDate = (pulses) => {
    const grouped = pulses.reduce((acc, pulse) => {
      const date = new Date(pulse.createdAt).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(pulse);
      return acc;
    }, {});

    // Convert to array and sort dates in descending order
    return Object.entries(grouped)
      .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA));
  };

  return (
    <Container maxWidth="md" sx={{ mt: 0, mb: 4 }}>
      <PulseHeader 
        userEmail={userEmail}
        isLoading={isLoading}
        handleLogout={onLogout}
      />

      <PulseTabs currentTab={1} />

      <Box sx={{ mb: 4 }}>
        <FormControl fullWidth>
          <InputLabel id="topic-select-label">Filter by topic</InputLabel>
          <Select
            labelId="topic-select-label"
            id="topic-select"
            value={selectedTopic}
            label="Filter by topic"
            onChange={handleTopicChange}
            sx={{ textAlign: 'left' }}
          >
            <MenuItem value="all">All pulses</MenuItem>
            
            {enabledTopics.custom.length > 0 && [
              <ListSubheader key="custom-header" sx={{ fontWeight: 'bold', color: 'black' }}>Custom pulses</ListSubheader>,
              enabledTopics.custom.map(topic => (
                <MenuItem key={`custom-${topic.id}`} value={topic.topic}>
                  {topic.topic}
                </MenuItem>
              ))
            ].flat()}
            
            {enabledTopics.popular.length > 0 && [
              <ListSubheader key="popular-header" sx={{ fontWeight: 'bold', color: 'black' }}>Popular pulses</ListSubheader>,
              enabledTopics.popular.map(topic => (
                <MenuItem key={`popular-${topic.id}`} value={topic.topic}>
                  {topic.topic}
                </MenuItem>
              ))
            ].flat()}
          </Select>
        </FormControl>
      </Box>

      {isLoading ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          {pulses.map(([date, dayPulses]) => (
            <Box key={date} sx={{ mb: 6 }}>
              <Typography 
                variant="h5" 
                sx={{ 
                  textAlign: 'left',
                  mb: 3, 
                  pb: 1, 
                  borderBottom: '2px solid #000',
                  fontWeight: 'bold'
                }}
              >
                {date}
              </Typography>

              {dayPulses.map((pulse, index) => (
                <Box 
                  key={`${pulse.id}-${index}`} 
                  sx={{ 
                    textAlign: 'left',
                    mb: 4,
                    p: 3,
                    border: '1px solid #eaeaea',
                    borderRadius: '5px'
                  }}
                >
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      mb: 2,
                      pb: 1,
                      borderBottom: '2px solid #000'
                    }}
                  >
                    {pulse.topic}
                  </Typography>
                  <div
                    dangerouslySetInnerHTML={{ 
                      __html: marked.parse(pulse.content, { 
                        renderer: getMarkedRenderer(referenceDisplay),
                        breaks: true
                      }) 
                    }}
                    style={{ color: '#333' }}
                  />
                </Box>
              ))}
            </Box>
          ))}

          {totalPages > 1 && (
            <Stack spacing={2} alignItems="center" sx={{ mt: 4, mb: 4 }}>
              <Pagination 
                count={totalPages} 
                page={page} 
                onChange={handlePageChange}
                color="primary"
                size="large"
                showFirstButton 
                showLastButton
              />
            </Stack>
          )}
        </>
      )}

      <Footer />
    </Container>
  );
};

export default Feed; 