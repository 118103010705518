import React, { useState } from 'react';
import axios from 'axios';
import './SubscribeButton.css';

const SubscribeButtonCheckout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubscribe = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payments/create-checkout-session`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true
        }
      );
      
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error initiating checkout:', error);
      setError('Failed to initiate checkout');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="subscribe-container">
      <button 
        onClick={handleSubscribe}
        className={`subscribe-button ${isLoading ? 'loading' : ''}`}
        disabled={isLoading}
      >
        {isLoading ? (
          <span className="loading-spinner"></span>
        ) : (
          'Subscribe to Plus'
        )}
      </button>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default SubscribeButtonCheckout; 