import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SubscribeButtonCheckout from './SubscribeButtonCheckout';
import Footer from './Footer';
import CancelSubscriptionModal from './CancelSubscriptionModal';

const Account = ({ userEmail }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    status: null,
    cancelAtPeriodEnd: false,
    currentPeriodEnd: null,
    subscription_plan: 'free'
  });
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  const fetchSubscriptionStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/payments/subscription-status`,
        { withCredentials: true }
      );
      setSubscriptionDetails({
        status: response.data.status,
        cancelAtPeriodEnd: response.data.cancelAtPeriodEnd,
        currentPeriodEnd: response.data.currentPeriodEnd,
        subscription_plan: response.data.subscription_plan
      });
    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  };

  const handleBack = () => {
    navigate('/pulse');
  };

  const getSubscriptionText = () => {
    if (subscriptionDetails.cancelAtPeriodEnd) {
      const endDate = new Date(subscriptionDetails.currentPeriodEnd).toLocaleDateString();
      return `Your ${subscriptionDetails.subscription_plan} plan will be canceled at the end of your period, on ${endDate}`;
    }

    switch (subscriptionDetails.subscription_plan) {
      case 'premium':
        return "You are on the Premium plan. To update payment method please email andrew@distll.ai";
      case 'plus':
        return "You are on the Plus plan. To update payment method please email andrew@distll.ai";
      default:
        return "You are on the Free plan";
    }
  };

  const handleCancelSubscription = async () => {
    setCancelLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payments/cancel-subscription`,
        {},
        { withCredentials: true }
      );
      
      // Refresh subscription status
      await fetchSubscriptionStatus();
      setShowCancelModal(false);
    } catch (error) {
      console.error('Error canceling subscription:', error);
      // You might want to show an error message to the user here
    } finally {
      setCancelLoading(false);
    }
  };

  const handleResumeSubscription = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payments/resume-subscription`,
        {},
        { withCredentials: true }
      );
      
      // Refresh subscription status
      await fetchSubscriptionStatus();
    } catch (error) {
      console.error('Error resuming subscription:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container 
        maxWidth="md" 
        sx={{
          px: isMobile ? 2 : 3,
          py: isMobile ? 2 : 4,
          flex: 1,
        }}
      >
        <Box
          component="header"
          sx={{
            position: 'sticky',
            top: 0,
            bgcolor: 'background.paper',
            zIndex: 1,
            pb: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography 
              component="h1" 
              variant={isMobile ? "h5" : "h4"} 
              sx={{ 
                fontWeight: 600,
                minWidth: 'fit-content',
              }}
            >
              dist*ll
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={handleBack}
              variant="text"
              sx={{
                py: 1,
                px: 2,
                color: 'black',
                border: '1px solid transparent',
                '&:hover': {
                  border: '1px solid black',
                  bgcolor: 'transparent',
                },
                minWidth: 'auto',
              }}
            >
              Back
            </Button>
          </Box>
        </Box>

        <Card 
          sx={{ 
            mb: 4,
            mt: 3,
            backgroundColor: '#f8f8f8',
            border: '1px solid #eaeaea',
            boxShadow: 'none',
          }}
        >
          <CardContent>
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 600, textAlign: 'left' }}>
              Account Details
            </Typography>
            <Typography variant="h6" sx={{ mb: 0, fontWeight: 600, textAlign: 'left' }}>
              Email
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left' }}>
              {userEmail}
            </Typography>
            <Typography variant="h6" sx={{ mt: 2, mb: 0, fontWeight: 600, textAlign: 'left' }}>
              Current Plan
            </Typography>
            <Box sx={{ mb: 2, textAlign: 'left' }}>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2, textAlign: 'left' }}>
                {getSubscriptionText()}
              </Typography>
              
              {subscriptionDetails.subscription_plan === 'free' && (
                <SubscribeButtonCheckout />
              )}

              {(subscriptionDetails.subscription_plan === 'plus' || subscriptionDetails.subscription_plan === 'premium') && (
                <>
                  {subscriptionDetails.cancelAtPeriodEnd ? (
                    <Button
                      onClick={handleResumeSubscription}
                      variant="contained"
                      disabled={isLoading}
                      sx={{
                        bgcolor: 'black',
                        '&:hover': {
                          bgcolor: '#333',
                        },
                      }}
                    >
                      Resume {subscriptionDetails.subscription_plan} Plan
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setShowCancelModal(true)}
                      variant="outlined"
                      sx={{
                        color: 'black',
                        borderColor: 'black',
                        '&:hover': {
                          borderColor: 'black',
                          backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        },
                      }}
                    >
                      Cancel {subscriptionDetails.subscription_plan} Plan
                    </Button>
                  )}
                </>
              )}
            </Box>
          </CardContent>
        </Card>

        <Card 
          sx={{ 
            mb: 4,
            mt: 3,
            backgroundColor: '#f8f8f8',
            border: '1px solid #eaeaea',
            boxShadow: 'none',
          }}
        >
          <CardContent>
          <Typography variant="h4" sx={{ mb: 2, fontWeight: 600, textAlign: 'left' }}>
              Plans
            </Typography>
            <Typography variant="h6" sx={{ mb: 0, fontWeight: 600, textAlign: 'left' }}>
              Basic
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left' }}>
              Free
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left' }}>
              Popular Pulses: 2
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left' }}>
              Custom Pulses: 1
            </Typography>
            <Typography variant="h6" sx={{ mt: 2,mb: 0, fontWeight: 600, textAlign: 'left' }}>
              Plus
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left' }}>
              $7/month
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left' }}>
              Popular Pulses: Unlimited
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left' }}>
              Custom Pulses: 10
            </Typography>
            <Typography variant="h6" sx={{ mt: 2,mb: 0, fontWeight: 600, textAlign: 'left' }}>
              Premium
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left' }}>
              Email andrew@distll.com for pricing
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left' }}>
              Popular Pulses: Unlimited
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left' }}>
              Custom Pulses: Unlimited
            </Typography>
          </CardContent>
        </Card>

      </Container>
      <Footer />
      <CancelSubscriptionModal
        open={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={handleCancelSubscription}
        isLoading={cancelLoading}
      />
    </Box>
  );
};

export default Account; 